import useCleverLoginButton from './useCleverLoginButton.hook';
import SignInButton from '../SignInButton/SignInButton';

export default function CleverLoginButton({
  text,
  redirectTo,
  redirectErrorTo,
}) {
  const { state } = useCleverLoginButton({ redirectTo, redirectErrorTo });

  return (
    <SignInButton
      url={state.loginUrl}
      text={text}
      logo={
        process.env.NEXT_PUBLIC_CLOUDFRONT_STATIC_HOST + '/img/clever-white.png'
      }
      logoWidth="64px"
      logoHeight="20px"
      provider="clever"
    />
  );
}
