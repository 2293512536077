import { useContext, useEffect } from 'react';
import login from '@/api/auth/login.endpoint';
import { useRouter } from 'next/router';
import { GlobalContext } from '../../../shared/GlobalContext/GlobalContext';
import setAuthenticatedUser from 'src/modules/auth/setAuthenticatedUser';
import useCustomHook from '@/hooks/useCustomHook';
import resetPassword from '@/api/auth/resetPassword.endpoint';
import { notification } from 'antd';

export default function useRockalinguaLoginButton({
  inputRef,
  passwordRef,
  loginInitialValue,
  isStudent,
}) {
  const context = useContext(GlobalContext);
  const router = useRouter();
  const { state, actions } = useCustomHook({
    defaultState: {
      formVisible: false,
      resetPasswordVisible: false,
      login: loginInitialValue,
      password: '',
      email: '',
      loadingLogin: false,
      loadingReset: false,
      errorMessage: null,
    },
    actions: {
      toggleForm: () => {
        actions.setState({
          formVisible: !state.formVisible,
        });
      },
      setLogin: (login) => {
        actions.setState({ login, errorMessage: null });
      },
      setPassword: (password) => {
        actions.setState({ password, errorMessage: null });
      },
      setEmail: (email) => {
        actions.setState({ email, errorMessage: null });
      },
      setResetPasswordVisible: (resetPasswordVisible) => {
        actions.setState({ resetPasswordVisible, errorMessage: null });
      },
      doLogin: async () => {
        try {
          actions.setState({ loadingLogin: true, errorMessage: null });
          const response = await login({
            login: state.login,
            password: state.password,
            preventRedirectOnUnauthorized: true,
          });
          const user = await setAuthenticatedUser({
            accessToken: response.token,
            context,
          });
          if (user.admin) {
            router.push('/admin/dashboard');
          } else if (user.teacher) {
            if (user.teacher.data.pendingWelcomeMigrated) {
              router.push('/welcome/migrated');
            } else {
              router.push('/dashboard');
            }
          } else if (user.student) {
            router.push('/student/dashboard');
          }
        } catch (e) {
          actions.setState({
            loadingLogin: false,
            errorMessage: [
              e.feedbackData?.message || 'Something went wrong. Try again.',
              isStudent
                ? `If you don't remember your username or password ask your teacher.`
                : '',
            ],
          });
        }
      },
      resetPassword: async () => {
        try {
          actions.setState({ loadingReset: true, errorMessage: null });
          await resetPassword(state.email);
          actions.setState({ loadingReset: false, formVisible: false });
          notification.success({
            message: 'Reset password',
            description:
              "We've sent you an email to reset your password. Please check your inbox",
            duration: 0,
          });
        } catch (e) {
          actions.setState({
            loadingReset: false,
            errorMessage: [
              e.feedbackData?.message || 'Something went wrong. ',
              'Check if your email is correct and it is the one you used to sign up.',
            ],
          });
        }
      },
    },
  });

  useEffect(() => {
    if (state.formVisible) {
      const refToFocus = loginInitialValue ? passwordRef : inputRef;
      setTimeout(() => {
        refToFocus.current?.focus();
      }, 100);
    }
  }, [state.formVisible]);

  useEffect(() => {
    if (router.isReady) {
      const { userType } = router.query;
      if (userType) {
        actions.setState({ formVisible: true });
      }
    }
  }, [router.isReady]);

  return { state, actions };
}
