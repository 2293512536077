import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../../shared/GlobalContext/GlobalContext';
import SSOConf from '../../../../modules/constants/SSOConf';

export default function useCleverLoginButton({ redirectTo, redirectErrorTo }) {
  const [state, setState] = useState({});
  const context = useContext(GlobalContext);
  useEffect(() => {
    const loginUrl = SSOConf.clever.getLoginUrl({
      user: context.state.user,
      redirectTo,
      redirectErrorTo,
    });
    setState({ loginUrl });
  }, [context, redirectTo]);
  return { state };
}
