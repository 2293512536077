import useGoogleClassroomLoginButton from './useGoogleClassroomLoginButton.hook';
import SignInButton from '../SignInButton/SignInButton';

export default function GoogleClassroomLoginButton({
  text,
  redirectTo,
  redirectErrorTo,
  isStudent,
}) {
  const { state } = useGoogleClassroomLoginButton({
    redirectTo,
    redirectErrorTo,
    isStudent,
  });
  return (
    <SignInButton
      url={state.loginUrl}
      text={text}
      logo={
        process.env.NEXT_PUBLIC_CLOUDFRONT_STATIC_HOST +
        '/img/classroom-logo.png'
      }
      logoWidth="111px"
      logoHeight="25px"
      provider="google"
    />
  );
}
