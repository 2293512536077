import getMe from '@/api/auth/getMe.endpoint';
import { setAxiosHeader } from '../api/axiosInterceptors';

export default async function setAuthenticatedUser({
  accessToken,
  context,
  impersonatedBy = [],
  nextUrl,
}) {
  setAxiosHeader(accessToken);
  const user = await getMe();
  context.actions.setSession({
    accessToken,
    user,
    impersonatedBy,
    nextUrl,
  });

  return user;
}
