import Colors from '@/constants/Colors';
import { pxToVw } from '@/modules/styled/cssUnits';
import Button from '@/shared/Button/Button';
import styled from 'styled-components';

export const StyledRockalinguaLoginButton = styled.div`
  position: relative;

  .form-container {
    position: absolute;
    z-index: 1;
    width: calc(100% + 20px);
    left: -10px;
    top: 20px;
    background: white;
    border: 1px solid ${Colors.border};
    border-radius: 10px;
    padding: 40px 0 0;
    min-height: 350px;

    .form-fields {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .ant-input,
      .ant-input-password {
        margin-left: 20px;
        margin-right: 20px;
        padding-left: 15px;
        width: calc(100% - 40px);

        border-radius: 30px;
      }
      .ant-input-password .ant-input {
        margin-left: 0;
        padding-left: 0;
      }

      .ant-btn {
        margin-left: 20px;
        margin-right: 20px;
        width: calc(100% - 40px);
      }
    }

    .message {
      color: ${Colors.error};
      padding: ${pxToVw(20)};
    }

    .info {
      font-size: 14px;
      text-align: center;
      //color: ${Colors.lightGray};
    }
  }

  .reset-password-form {
    display: flex;
    flex-direction: column;
    background: ${Colors.lighterBlue};
    gap: 10px;
    padding: 20px 0;
    border-radius: 0 0 10px 10px;
  }
`;

export const StyledLoginButton = styled(Button)`
  z-index: 2;
  width: 100%;
  color: ${Colors.textDark};
  border: 2px solid ${Colors.textDark};
  box-shadow: 0px 5px 8px -9px #000000;
  transition: all 0.2s ease-in-out;
  text-align: left;
  padding: 0;
  padding-left: 20px;
  background-color: white;
  overflow: hidden;

  &.go-back {
    width: 2.5em;
    padding-left: 0;
    border-color: ${Colors.border};
    color: ${Colors.text};
    margin-left: calc(100% - 2.5em);
  }

  .centered {
    width: 100%;
    text-align: center;
  }

  &:hover,
  &:focus {
    color: ${Colors.textDark};
    border-color: ${Colors.text};
    background-color: white;
    text-shadow: 0px 2px 2px #ddd;
  }
`;
