/* eslint-disable @next/next/no-img-element */
import { StyledSignInButton } from './SignInButton.styled';

export default function SignInButton({
  url,
  logo,
  logoAlt,
  logoWidth,
  logoHeight,
  text = '',
  provider,
}) {
  return (
    <StyledSignInButton
      href={url}
      className={`${provider} ${text ? '' : 'centered'}`}
    >
      <div className="btn-text">{text}</div>
      <img src={logo} alt={logoAlt} width={logoWidth} height={logoHeight} />
    </StyledSignInButton>
  );
}
