import axios from '@/api/axiosInstance';

export default async function resetPassword(email) {
  const response = await axios({
    method: 'post',
    url: `${process.env.NEXT_PUBLIC_API}/auth/resetPassword`,
    data: { email },
  });
  return response.data;
}
