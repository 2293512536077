import { Input, Form, Alert } from 'antd';
import { useRef } from 'react';
import {
  StyledLoginButton,
  StyledRockalinguaLoginButton,
} from './RockalinguaLoginButton.styled';
import useRockalinguaLoginButton from './useRockalinguaLoginButton.hook';
import { motion, AnimatePresence } from 'framer-motion';
import Button from '@/shared/Button/Button';
import { CloseOutlined } from '@ant-design/icons';

export default function RockalinguaLoginButton({
  buttonText = 'Login',
  text,
  isStudent,
  loginInitialValue,
}) {
  const inputRef = useRef();
  const passwordRef = useRef();
  const { state, actions } = useRockalinguaLoginButton({
    inputRef,
    passwordRef,
    loginInitialValue,
    isStudent,
  });
  return (
    <StyledRockalinguaLoginButton>
      <AnimatePresence>
        {state.formVisible ? (
          <motion.div
            key="form-container"
            initial={{ opacity: 0, zIndex: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {
              <div className="form-container">
                <Form onFinish={actions.doLogin}>
                  <div className="form-fields">
                    <Input
                      placeholder={isStudent ? 'User' : 'User / Email'}
                      value={state.login}
                      onChange={(event) => {
                        actions.setLogin(event.target.value);
                      }}
                      ref={inputRef}
                      autoComplete="username"
                      size="large"
                    />
                    <Input.Password
                      placeholder="Password"
                      type="Password"
                      value={state.password}
                      onChange={(event) => {
                        actions.setPassword(event.target.value);
                      }}
                      ref={passwordRef}
                      autoComplete="password"
                      size="large"
                    />
                    <Button
                      htmlType="submit"
                      loading={state.loadingLogin}
                      type="primary"
                      block
                      size="large"
                      disabled={!state.login || !state.password}
                    >
                      {buttonText}
                    </Button>

                    {!isStudent ? (
                      <>
                        <Button
                          onClick={() =>
                            actions.setResetPasswordVisible(
                              !state.resetPasswordVisible
                            )
                          }
                          block
                          type="link"
                          icon={
                            state.resetPasswordVisible ? (
                              <CloseOutlined />
                            ) : null
                          }
                          className="mt"
                        >
                          Reset password
                        </Button>
                        {state.resetPasswordVisible ? (
                          <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            className="reset-password-form"
                          >
                            <ResetPasswordForm
                              state={state}
                              actions={actions}
                            />
                          </motion.div>
                        ) : null}
                      </>
                    ) : null}

                    {state.errorMessage ? (
                      <div className="message">
                        <Alert
                          message={state.errorMessage?.map((error, index) => (
                            <div key={index}>{error}</div>
                          ))}
                          type="error"
                          showIcon
                        />
                      </div>
                    ) : null}
                  </div>
                </Form>
              </div>
            }
          </motion.div>
        ) : null}
      </AnimatePresence>
      <StyledLoginButton
        size="large"
        onClick={actions.toggleForm}
        className={state.formVisible ? 'go-back' : ''}
      >
        {state.formVisible ? (
          <span className="centered">
            <CloseOutlined />
          </span>
        ) : (
          text || (
            <span>
              <span>
                Log In with <strong>{isStudent ? 'user' : 'user/email'}</strong>{' '}
                and{' '}
              </span>
              <strong>password</strong>
            </span>
          )
        )}
      </StyledLoginButton>
    </StyledRockalinguaLoginButton>
  );
}

function ResetPasswordForm({ state, actions }) {
  return (
    <>
      <div className="info">Write your email to reset the password.</div>
      <Input
        placeholder={'Your email'}
        value={state.email}
        onChange={(event) => {
          actions.setEmail(event.target.value);
        }}
        autoComplete="email"
        size="large"
        disabled={state.loadingReset}
      />
      <Button
        block
        type="primary"
        disabled={!state.email}
        onClick={actions.resetPassword}
        loading={state.loadingReset}
      >
        Send me the reset link
      </Button>
    </>
  );
}
