import useClasslinkLoginButton from './useClasslinkLoginButton.hook';
import SignInButton from '../SignInButton/SignInButton';

export default function ClasslinkLoginButton({
  text,
  redirectTo,
  redirectErrorTo,
}) {
  const { state } = useClasslinkLoginButton({ redirectTo, redirectErrorTo });

  return (
    <SignInButton
      url={state.loginUrl}
      text={text}
      logo={
        process.env.NEXT_PUBLIC_CLOUDFRONT_STATIC_HOST +
        '/img/classlink-white.png'
      }
      logoWidth="100px"
      logoHeight="17px"
      provider="classlink"
    />
  );
}
