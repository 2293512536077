import axios from '@/api/axiosInstance';

export default async function login({
  login,
  password,
  preventRedirectOnUnauthorized,
}) {
  const response = await axios({
    method: 'post',
    url: `${process.env.NEXT_PUBLIC_API}/auth/login`,
    data: { login, password, timeOffset: new Date().getTimezoneOffset() },
    preventRedirectOnUnauthorized,
  });
  return response.data;
}
