import styled from 'styled-components';
import Colors from '@/constants/Colors';

export const StyledSignInButton = styled.a`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  min-height: 40px;
  border-radius: 50vh;
  color: white;
  box-shadow: 0px 5px 8px -9px #000000;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: white;
    text-shadow: 0px 2px 2px #000;
  }

  &.centered {
    justify-content: center;
    padding-left: 0px;
  }

  .btn-text {
    font-size: 16px;
    padding-right: 8px;
  }

  &.clever {
    background: ${Colors.providerBackgrounds.clever};
    border: 2px solid ${Colors.providerBackgrounds.clever};
  }
  &.classlink {
    background: ${Colors.providerBackgrounds.classlink};
    border: 2px solid ${Colors.providerBackgrounds.classlink};
  }
  &.google {
    color: ${Colors.textDark};
    background: ${Colors.providerBackgrounds.google};
    border: 2px solid ${Colors.textDark};
    &:hover {
      color: black;
      text-shadow: 0px 2px 2px #ddd;
    }
  }
`;
